@tailwind base;
@tailwind components;
@tailwind utilities;

.poppins-thin {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: italic;
}

:root {
	font-size: 62.5%;
	--background: #111;
	--bg-opaque: hsla(0, 0%, 7%, 0.25);
	--background-light: #232323;
	--background-dark: #080808;
	--service-background: #fff;
	--text: #000000;
	--brand: #6c63ff;
	--text-xxs: 1rem;
	--text-xs: 1.6rem;
	--text-sm: 1.8rem;
	--text-lsm: 2rem;
	--text-md: 2.2rem;
	--text-lg: 3.6rem;
	--text-xl: 5.6rem;
	--text-2xl: 9.6rem;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

::selection {
	background: var(--brand);
	color: var(--background);
}

body,
html {
	max-width: 100vw;
	scroll-behavior: smooth;
	font-size: var(--text-sm);
}

body {
	color: var(--text);
	background: var(--background);
}

a {
	color: inherit;
	text-decoration: none;
}

.section-wrapper {
	position: relative;
	z-index: 10;
	max-width: 1150px;
	margin: auto;
	padding: 9.6rem;
	overflow: hidden;
}

@media (max-width: 768px) {
	.section-wrapper {
		padding: 7.2rem 2.4rem;
	}
}

.myHeightClass {
	height: 64rem;
}

.myFlex {
	display: block;
	text-align: -webkit-center;
}

.myAFont {
	font-size: small;
}

.chip {
	font-size: var(--text-xs);
	/* background: var(--background-light); */
	padding: 0.2rem 0.8rem;
	border-radius: 999px;
	color: var(--brand);
}

body {
	margin: 0;
	/* font-family: 'Druk Wide Bold', sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.sidebar {
	/* background: var(--background-light); */
	height: 100vh;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 20;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	color: #000000;
}

.sidebar a {
	text-decoration: none;
	height: 100px;
	flex-shrink: 0;
	writing-mode: vertical-lr;
	font-size: var(--text-sm);
	font-weight: 200;
	display: flex;
	align-items: center;
	justify-content: center;
	/* opacity: 0.5; */
	border-right: 1px solid transparent;
	transition: all 0.1s;
	width: 100%;
}

.sidebar .sidebar_selected,
.sidebar a:hover {
	background: #f0eded;
	/* border-right: 1px solid var(--brand); */
	opacity: 1;
	color: #000000;
}

.sidebar_logo {
	flex-shrink: 0;
	width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--background);
	border-radius: 4px;
	margin: 1.8rem 0;
}

.sidebar_logo,
.sidebar_logo span {
	font-size: var(--text-md);
	font-weight: 900;
	line-height: 1;
}

.sidebar_logo span {
	color: var(--brand);
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.standardbutton_standardButton {
	outline: none;
	border: none;
	cursor: pointer;
	padding: 1.2rem 2.4rem;
	background: var(--brand);
	color: var(--background-dark);
	font-size: var(--text-sm);
	border-radius: 4px;
	position: relative;
	z-index: 20;
	overflow: hidden;
	transition: opacity 0.2s;
}

.standardbutton_standardButton:hover {
	opacity: 0.9;
}

.dotgrid_dotGrid {
	position: absolute;
	top: 12px;
	bottom: 12px;
	right: 3.6rem;
	max-width: 75%;
	display: grid;
	z-index: 0;
}

.dotgrid_dotWrapper {
	padding: 0.8rem;
	cursor: crosshair;
	transition: background 0.25s;
	border-radius: 8px;
}

.dotgrid_dot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: linear-gradient(180deg, var(--background), var(--text));
	opacity: 0.35;
}

.dotgrid_dotWrapper:hover {
	background: hsla(0, 0%, 100%, 0.15);
}

.dotgrid_dotWrapper:hover > .dotgrid_dot {
	background: linear-gradient(
		180deg,
		var(--background),
		var(--brand)
	) !important;
	opacity: 1 !important;
}

.hero_hero {
	margin-bottom: 9.6rem;
}

@media (max-width: 768px) {
	.hero_hero {
		margin-bottom: 4.8rem;
	}
}

.hero_copyWrapper {
	position: relative;
	z-index: 10;
	width: -moz-fit-content;
	width: fit-content;
	margin: 4.8rem 0;
}

.hero_title {
	font-size: var(--text-2xl);
	font-weight: 900;
	line-height: 1.1;
}

.hero_title span {
	color: var(--brand);
}

@media (max-width: 768px) {
	.hero_title {
		font-size: var(--text-xl);
	}
}

.hero_subTitle {
	font-size: var(--text-lg);
	line-height: 1.1;
	margin-top: 1.6rem;
	font-weight: 200;
}

.hero_subTitle span {
	font-weight: 700;
	color: var(--brand);
}

.hero_aboutCopy {
	margin: 2.4rem 0;
	max-width: 700px;
	font-weight: 200;
}

.headinglinks_links {
	display: flex;
	gap: 1.6rem;
}

.headinglinks_links a {
	opacity: 0.5;
	position: relative;
	z-index: 20;
}

.headinglinks_links a:hover {
	color: var(--brand);
	opacity: 1;
}
.outlinebutton_outlineButton {
	outline: none;
	border: none;
	cursor: pointer;
	padding: 0.8rem 2.4rem;
	border: 1px solid var(--brand);
	background: transparent;
	color: var(--brand);
	font-size: var(--text-sm);
	border-radius: 4px;
	position: relative;
	z-index: 20;
	overflow: hidden;
	transition: color 0.2s;
}

.outlinebutton_outlineButton:before {
	content: '';
	position: absolute;
	z-index: -1;
	background: var(--brand);
	height: 100%;
	width: 100%;
	left: -100%;
	top: -100%;
	transition: left 0.2s, top 0.2s;
}

.outlinebutton_outlineButton:hover {
	color: var(--background-dark);
}

.outlinebutton_outlineButton:hover:before {
	left: 0;
	top: 0;
}

.heading_heading {
	height: calc(45px + 0);
	/* padding: 0 3.6rem; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 20;
	background: var(--bg-opaque);
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	font-size: var(--text-md);
	font-weight: 700;
}

@media (max-width: 768px) {
	.heading_heading {
		padding: 0 2.4rem;
	}
}

.header_sectionHeader {
	display: flex;
	align-items: center;
	gap: 2.4rem;
	margin-bottom: 2.4rem;
	flex-direction: row-reverse;
}

.header_title {
	font-size: var(--text-xl);
	font-weight: 900;
	text-align: end;
}

.header_title span {
	color: var(--brand);
}

@media (max-width: 768px) {
	.header_title {
		font-size: var(--text-lg);
	}
}

.header_line {
	width: 100%;
	height: 1px;
	background: var(--text);
	opacity: 0.3;
}

.stats_stats {
	position: relative;
}

.stats_statColumn h4 {
	display: flex;
	align-items: center;
	margin-bottom: 2.4rem;
}

.stats_statColumn h4 span {
	font-size: var(--text-md);
	font-weight: 700;
	position: relative;
	margin-left: 0.8rem;
}

.stats_statGrid {
	display: flex;
	flex-wrap: wrap;
	gap: 1.2rem;
	margin-bottom: 4.8rem;
	flex-direction: row-reverse;
}

.about_about {
	display: grid;
	grid-template-columns: 1fr 300px;
	grid-gap: 2.4rem;
	gap: 2.4rem;
}

@media (max-width: 900px) {
	.about_about {
		grid-template-columns: 1fr;
	}
}

.about_aboutText {
	margin-bottom: 2.4rem;
	font-weight: 200;
}

.about_links {
	display: flex;
	align-items: center;
	gap: 1.6rem;
}

.about_linksText {
	display: flex;
	align-items: center;
	gap: 0.8rem;
	font-size: var(--text-sm);
	color: var(--brand);
}

.about_highlightFirstLetter:first-letter {
	background: var(--background-light);
	padding: 1rem;
	border-radius: 0.4rem;
	font-size: var(--text-md);
	font-weight: 700;
	margin-right: 0.6rem;
	float: left;
}

.projectmodal_modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100000000;
	height: 100vh;
	padding: 4.8rem 1.2rem;
	background: var(--bg-opaque);
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.projectmodal_modalCard {
	width: 100%;
	max-width: 700px;
	height: -moz-fit-content;
	height: fit-content;
	border-radius: 1.2rem;
	overflow: hidden;
	background: var(--background-light);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	cursor: auto;
}

.projectmodal_modalImage {
	width: 100%;
}

.projectmodal_modalContent {
	padding: 2.4rem;
}

.projectmodal_modalContent h4 {
	font-size: var(--text-lg);
}

.projectmodal_modalTech {
	display: flex;
	flex-wrap: wrap;
	gap: 1.2rem;
	font-size: var(--text-xs);
	color: var(--brand);
	margin: 0.2rem 0 2.4rem;
}

.projectmodal_suppliedContent {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	font-size: var(--text-xs);
}

.projectmodal_modalFooter {
	margin-top: 2.4rem;
}

.projectmodal_linksText {
	font-weight: 700;
	font-size: var(--text-md);
	margin-bottom: 0.8rem;
}

.projectmodal_linksText span {
	color: var(--brand);
}

.projectmodal_links {
	display: flex;
	align-items: center;
	gap: 1.2rem;
}

.projectmodal_links a {
	display: flex;
	align-items: center;
	gap: 0.4rem;
	font-size: var(--text-xs);
	color: var(--brand);
}

.projectmodal_links a:hover {
	text-decoration: underline;
}

.projectmodal_closeModalBtn {
	background: none;
	border: none;
	color: var(--text);
	font-size: var(--text-md);
	position: absolute;
	top: 1.2rem;
	right: 1.2rem;
	cursor: pointer;
}

.projects_projects {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 3.2rem;
	gap: 3.2rem;
}

@media (max-width: 768px) {
	.projects_projects {
		grid-template-columns: 1fr;
	}
}

.projects_projectImage {
	width: 100%;
	aspect-ratio: 16/9;
	background: var(--background-light);
	cursor: pointer;
	position: relative;
	border-radius: 0.8rem;
	overflow: hidden;
}

.projects_projectImage img {
	width: 85%;
	position: absolute;
	bottom: 0;
	left: 50%;
	translate: -50% 20%;
	transition: all 0.25s;
	border-radius: 0.4rem;
}

.projects_projectImage img:hover {
	width: 90%;
	rotate: 2deg;
}

.projects_projectCopy {
	margin: 1.6rem 0;
}

.projects_projectTitle {
	display: flex;
	align-items: center;
	gap: 1.2rem;
}

.projects_projectTitle h4 {
	font-weight: 700;
	font-size: var(--text-md);
	flex-shrink: 0;
	max-width: calc(100% - 150px);
}

.projects_projectTitle a {
	opacity: 0.75;
	transition: opacity 0.25s;
}

.projects_projectTitle a:hover {
	opacity: 1;
}

.projects_projectTitleLine {
	width: 100%;
	height: 1px;
	background: var(--text);
	opacity: 0.3;
}

.projects_projectDescription {
	font-weight: 200;
}

.projects_projectDescription span {
	display: inline-block;
	font-size: var(--text-xs);
	font-weight: 400;
	color: var(--brand);
	cursor: pointer;
}

.projects_projectDescription span:hover {
	text-decoration: underline;
}

.projects_projectTech {
	display: flex;
	flex-wrap: wrap;
	gap: 1.2rem;
	font-size: var(--text-xs);
	color: var(--brand);
	margin: 0.8rem 0;
}

.experience_experience {
	margin-bottom: 2.4rem;
	padding: 0 1.2rem 2.4rem;
	border-bottom: 1px solid var(--background-light);
}

.experience_heading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.2rem;
}

.experience_title {
	font-size: var(--text-md);
	font-weight: 700;
}

.experience_position {
	color: var(--brand);
	font-weight: 700;
}

.experience_description {
	margin-bottom: 1.8rem;
	font-weight: 200;
}

.experience_tech {
	display: flex;
	flex-wrap: wrap;
	gap: 1.2rem;
}

.contact_contactWrapper {
	max-width: 700px;
	margin: auto;
	border-radius: 1.2rem;
}

.contact_contactTitle {
	font-size: var(--text-2xl);
	text-align: center;
	font-weight: 900;
	line-height: 1;
}

.contact_contactTitle span {
	color: var(--brand);
}

@media (max-width: 768px) {
	.contact_contactTitle {
		font-size: var(--text-xl);
	}
}

.contact_contactCopy {
	text-align: center;
	font-weight: 200;
	margin: 2.4rem 0;
}

.contact_contactCopy a {
	color: var(--brand);
}

.contact_contactCopy a:hover {
	text-decoration: underline;
}

.contactInfo {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
	width: -moz-fit-content;
	width: fit-content;
	font-size: var(--text-xs);
	margin: auto;
	transition: color 0.25s;
}

.contactInfo1 {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
	width: -moz-fit-content;
	width: fit-content;
	font-size: var(--text-md);
	margin: auto;
	transition: color 0.25s;
}

.contactInfo2 {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
	width: -moz-fit-content;
	width: fit-content;
	font-size: var(--text-xs);
	margin: auto;
	transition: color 0.25s;
}

.contactInfo:hover {
	color: var(--brand);
}

.contactInfo2:hover {
	.c2 {
		color: var(--brand);
	}
}

.home_home {
	display: grid;
	grid-template-columns: 60px 1fr;
}

.myBubbleUI {
	width: 100%;
	max-width: 1000px;
	height: 500px;
	border-radius: 50px;
}

.child {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

/* .image_wrapper {
	margin: 0px 40px;
}

.image_wrapper img {
	width: 60px;
} */

/* .tech-div {
	border-radius: 50%;
	background-color: #ebecf3;
	display: flex;
	justify-content: center;
} */

.tech-logo {
	width: 4rem;
}

.projectmodal_modal__X7Nyj {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100000000;
	height: 100vh;
	padding: 4.8rem 1.2rem;
	background: var(--bg-opaque);
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.projectmodal_modalCard__sntB0 {
	width: 100%;
	max-width: 700px;
	height: -moz-fit-content;
	height: fit-content;
	border-radius: 1.2rem;
	overflow: hidden;
	background: var(--background-light);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	cursor: auto;
}

.projectmodal_modalImage__EWd63 {
	width: 100%;
}

.projectmodal_modalContent__loHbD {
	padding: 2.4rem 0;
}

.projectmodal_modalContent__loHbD h4 {
	font-size: var(--text-lg);
}

.projectmodal_modalTech__CGlgc {
	display: flex;
	flex-wrap: wrap;
	gap: 1.2rem;
	font-size: var(--text-xs);
	color: var(--brand);
	margin: 0.2rem 0 2.4rem;
}

.projectmodal_suppliedContent__9ZyRY {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	font-size: var(--text-xs);
}

.projectmodal_modalFooter__qkYgS {
	margin-top: 2.4rem;
}

.projectmodal_linksText__9hL89 {
	font-weight: 700;
	font-size: var(--text-md);
	margin-bottom: 0.8rem;
}

.projectmodal_linksText__9hL89 span {
	color: var(--brand);
}

.projectmodal_links__Q7utU {
	display: flex;
	align-items: center;
	gap: 1.2rem;
}

.projectmodal_links__Q7utU a {
	display: flex;
	align-items: center;
	gap: 0.4rem;
	font-size: var(--text-xs);
	color: var(--brand);
}

.projectmodal_links__Q7utU a:hover {
	text-decoration: underline;
}

.projectmodal_closeModalBtn__tv736 {
	background: none;
	border: none;
	color: var(--text);
	font-size: var(--text-md);
	position: absolute;
	top: 1.2rem;
	right: 1.2rem;
	cursor: pointer;
}
